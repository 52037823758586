<template>
  <div id="settings-wrapper">
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 100px; right: -35px; border-radius: 8px"
    >
      <v-icon large>mdi-monitor-dashboard</v-icon>
    </v-card>

    <v-menu
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
      min-width="300px"
      max-width="300px"
      max-height="300px"
      min-height="100%"
    >
      <div class="lemenu"  >
        <h3>Dashboard Management</h3>
        <div class="add">
          <v-text-field
            v-model="newDashboard"
            type="text"
            class="w-10"
            label="ajouter un dashboard"
          >
          </v-text-field>
          <v-icon @click="addDashboardItem" large>mdi-plus-box</v-icon>
        </div>
        <div class="table">
          <table>
            <tr v-for="DashboardItem in DashboardItems" :key="DashboardItem.id">
              <td
                width="90%"
                v-if="!DashboardItem.editable"
                class="editable-text"
              >
               <v-icon v-if="DashboardItem.active" color="yellow">mdi-star</v-icon> 
                <span
                  @click.prevent="GoToDashboard($event,DashboardItem)"
                  class="GoToDashboard"
                >
                 {{ DashboardItem.libelle }}
                </span>
              </td>
              <td width="90%" v-if="DashboardItem.editable" class="input-td">
                <input v-model="EditedItem.libelle" id="input" class="input" />

                <button v-if="DashboardItem.editable" @click="disableEditing">
                  <v-icon>mdi-close-thick</v-icon>
                </button>

                <button v-if="DashboardItem.editable" @click="saveEdit">
                  <v-icon>mdi-content-save</v-icon>
                </button>
              </td>
              <td width="10%">
                <ul id="coolMenu">
                  <li>
                    <v-icon @click="ShowDropDown(DashboardItem.id)"
                      >mdi-dots-vertical
                    </v-icon>
                    <ul>
                      <li
                        class="item"
                        @click.prevent="enableEditing(DashboardItem)"
                      >
                       <v-icon>mdi-pencil</v-icon>
                        Modifier
                      </li>
                      <li
                        class="item"
                        @click.prevent="deleteDashboardItem(DashboardItem)"
                      >
                        <v-icon>mdi-delete</v-icon>
                        Supprimer
                      </li>
                    </ul>
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
// Mixins
import Proxyable from "vuetify/lib/mixins/proxyable";
import { mapMutations, mapState } from "vuex";
import API from '../../../api-config';

export default {
  name: "DashboardCoreSettings",

  mixins: [Proxyable],

  data: () => ({
    selectedValue: null,
    CurrentActiveDashboard:null,
    CalledAPI: "user",
    endpoint: API.APIendpoint+"Dashboard/",
    token: localStorage.getItem("token"),
    newDashboard: "",
    temp: "",
    EditedIndex: null,
    EditedItem: {
      libelle: "",
      editable: true,
    },
    valeur: null,
    tempValue: null,
    editing: false,
  }),
  computed: {
     DashboardItems()
        {
          return this.$store.state.DashboardItems;
        },
    ...mapState(["barImage"]),
  },

  methods: {
    ...mapMutations({
      setBarImage: "SET_BAR_IMAGE",
    }),
    enableEditing: function (item) {
      this.disableEditing();
      this.EditedIndex = this.DashboardItems.indexOf(item);
      this.EditedItem = item;
      this.EditedItem.editable = true;
      this.tempValue = this.EditedItem.libelle;
      this.focusMethod();
    },
    disableEditing: function () {
      this.EditedItem.editable = false;
      this.EditedItem.libelle = this.tempValue;
    },
    saveEdit: function () {
      this.tempValue = this.EditedItem.libelle;
      this.$http
        .put(
          this.endpoint + this.CalledAPI,
          { id: this.EditedItem.id, libelle: this.EditedItem.libelle },
          { headers: { token: this.token } }
        )
        .then(
          (response) => {
            if(response.data.error == false)
            {
              if(this.EditedItem.active == true)
              {
                this.$store.commit('SwitchDashboard',this.EditedItem);
              }
            }
          },
          (response) => {
            //callback error
          }
        );

      //here

      this.valeur = this.tempValue;
      this.disableEditing();
    },
    focusMethod: function getFocus() {
      window.setTimeout(function () {
        document.getElementById("input").focus();
      }, 0);
    },
    GoToDashboard(event,item) {
      for(let i =0 ; i< this.DashboardItems.length ;i++)
      {
        this.DashboardItems[i].active = false;
      }
      item.active = true;
      this.CurrentActiveDashboard = item.id;
        this.$http.put(this.endpoint+"activate", {"id":this.CurrentActiveDashboard}, {headers: {'token':this.token}}).then(response => {
          }, response => {
            //callback error
         });
      this.$emit('ChangeDashboardName',item);
      this.$store.commit('SwitchDashboard',item);
      this.$store.commit('LoadCharts',this.CurrentActiveDashboard);
       this.$store.commit('LoadSelectItems',this.CurrentActiveDashboard);
    },
    addDashboardItem() {
      let duplicateItem = false;

      let LENGTH_MAX=25 , LENGTH_MIN=1;
      if(this.newDashboard.length > LENGTH_MAX || this.newDashboard.length < LENGTH_MIN)
      {
         this.$swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Le nom du Dashboard doit etre compris entre "+LENGTH_MIN+" et "+LENGTH_MAX+" caracteres !",
                  confirmButtonColor: "#3085d6",
                });
        this.newDashboard ="";
      }
      else{
      for (let i = 0; i < this.DashboardItems.length; i++) {
        if (this.DashboardItems[i].libelle === this.newDashboard) {
          duplicateItem = true;
          break;
        }
      }
      if (duplicateItem) {
        this.$swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Ce Dashboard exite déja !",
          confirmButtonColor: "#3085d6",
        });
        this.newDashboard = "";
      } else {
        this.$http
          .post(
            this.endpoint + this.CalledAPI,
            { libelle: this.newDashboard },
            { headers: { token: this.token } }
          )
          .then(
            (response) => {
              if (response.body.error == false) {
               
                this.$swal.fire({
                  icon: "success",
                  title: "Succes !",
                  text: "Dashboard ajouté avec succes",
                });
                 let item ={ "id":response.body.data, "libelle":this.newDashboard,editable: false,
                active:false}
                  this.$store.commit('AddInDashboardItems',item);
                   this.newDashboard = "";
              } else {
                this.$swal.fire({
                  icon: "error",
                  title: "Erreur",
                  text: "Ce Dashboard exite déja !",
                  confirmButtonColor: "#3085d6",
                });
              }
            },
            (response) => {
              //callback error
            }
          );
                        
      }
      }
      
    },
    deleteDashboardItem(item) {
       this.$http.delete(this.endpoint+this.CalledAPI,{headers: {'token':this.token},body : {'id':item.id}}).then(response => {
         if(response.body.error == false)
         {
          this.$store.commit('DeleteInDashboardItems',item);
         }
          }, response => {
            //callback error
         });
    },
    ShowDropDown(id) {
      //console.log(id);
    },
  },
};
</script>

<style lang="css" scoped>
#coolMenu,
#coolMenu ul {
  list-style: none;
}

#coolMenu {
  float: left;
  padding: 0;
}
#coolMenu > li {
  float: left;
}
#coolMenu .item {
  display: block;
  cursor: pointer;
  height: 2em;
  line-height: 2em;
  padding: 0 1.5em;
}
#coolMenu ul {
  position: absolute;
  right: 10px;
  display: none;
  z-index: 999;
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 0;
}
#coolMenu ul li a {
  width: 80px;
}
#coolMenu li:hover ul {
  display: block;
}
h3 {
  text-align: center;
  font-weight: normal;
  margin-top: 10px;
  margin-top: 10px;
}
table {
  width: 100%;
  overflow: auto;
}
table {
  font-family: arial, sans-serif;
  height: 70%;
}
td,
th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.input {
  width: 80%;
  border-bottom: 1px solid black !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.input:focus-visible {
  outline: none;
}
.GoToDashboard {
  cursor: pointer;
  padding: 5px 5px;
}
.GoToDashboard:hover {
  color: darkblue;
}
.add {
  display: flex;
  justify-content: space-around;
  margin: 0 15px;
}
.add-input {
  border: default;
}
.menu {
  background-color: white;
}
.table {
  margin: 0 5px;
  padding: 0 0 55px 0;
}

.lemenu {
  height: 100% !important;
  background-color: white !important;
  padding-top: 20px !important;
}
.lemenu.theme--dark{
    background-color: black !important;
  color:white !important;

  }
.v-settings {
  z-index: 100 !important;
}
</style>
